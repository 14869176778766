import { BusinessStatusIdTypes, IssueTypeIdTypes } from 'types/types'

export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY'
export const DEFAULT_DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm'
export const EMPTY_TEXT = '---'

export const API_URL = process.env.REACT_APP_API_URL ||
  (process.env.NODE_ENV === 'production' ? 'https://www.syniva.es/api' : 'https://dev-syniva.es:3000/api')
export const COMPANY_ORDER_ENDPOINT_TIPSA = 'company_order/tipsa_ticket'
export const ENDPOINT_TIPSA = 'tipsa_ticket'
export const ENDPOINT_CORREOS = 'correos_ticket'
export const ENDPOINT_VIEW_INVOICE = 'billings/pdf'
export const REACT_APP_BILLER_API_URL = process.env.REACT_APP_BILLER_API_URL || ''
export const FRONT_MAIN_URL = process.env.REACT_APP_FRONT_MAIN_URL ||
  (process.env.NODE_ENV === 'production' ? 'https://www.syniva.es/' : 'https://dev-syniva.es:3000/')

export const SIDEBAR_ITEMS_HEIGHT = 852

export const EXPRESS_SHIPMENT_METHOD_ID = 1
export const ESTANDAR_SHIPMENT_METHOD_ID = 2
export const ECONOMY_SHIPMENT_METHOD_ID = 4

export const EXPRESS_SHIPMENT_METHOD_TEXT = 'Envio expres'
export const ESTANDAR_SHIPMENT_METHOD_TEXT = 'Envio estandar'
export const ECONOMY_SHIPMENT_METHOD_TEXT = 'Envio economico'

export const SEQURA_PAYMENT_METHOD_ID = 5

export const CUSTOM_ORDER_MARKETPLACE_ID = 0
export const ORDER_STATUS_ID_PENDING_PAYMENT = 0
export const ORDER_STATUS_ID_REQUESTED = 1
export const PROCESSED_STATUS = 2
export const CANCELLED_STATUS = 3
export const RETURN_STATUS = 4
export const SUPPLEMENT_STATUS = 5
export const ADMITTED_STATUS = 6
export const NO_STOCK_STATUS = 7
export const PREPARED_STATUS = 8
export const SHIPPED_STATUS = 9
export const DELIVERED_STATUS = 10
export const PENALIZATION_STATUS = 11
export const REFUND_STATUS = 12
export const PARCIAL_RETURN_STATUS = 13
export const DECREASE_RETURN_STATUS = 14
export const INTERNAL_SPENDING_RETURN_STATUS = 15
export const ORDER_STATUS_ID_ON_HOLD = 16
export const ORDER_STATUS_ID_DENIED = 17
export const PENDING_PAYMENT_STATUS_TEXT = 'pendiente de pago'
export const REQUEST_STATUS_TEXT = 'solicitado'
export const PROCESS_STATUS_TEXT = 'procesado'
export const CANCEL_STATUS_TEXT = 'cancelado'
export const RETURN_STATUS_TEXT = 'devolucion'
export const SUPPLEMENT_STATUS_TEXT = 'suplemento'
export const ADMITTED_STATUS_TEXT = 'admitido'
export const NO_STOCK_STATUS_TEXT = 'no stock'
export const PREPARED_STATUS_TEXT = 'preparado'
export const SHIPPED_STATUS_TEXT = 'enviado'
export const DELIVERED_STATUS_TEXT = 'entregado'
export const PENALIZATION_STATUS_TEXT = 'penalization'
export const PENALIZATION_STATUS_TEXT_SPA = 'penalizacion'
export const REFUND_STATUS_TEXT = 'abono'
export const PARCIAL_RETURN_STATUS_TEXT = 'devolucion parcial'
export const DECREASE_RETURN_STATUS_TEXT = 'decrease'
export const INTERNAL_SPENDING_RETURN_STATUS_TEXT = 'gasto interno'
export const ORDER_STATUS_ON_HOLD_TEXT = 'en espera'
export const ORDER_STATUS_DENIED_TEXT = 'denegado'
export const CUSTOM_ORDER_TEXT = 'personalizado'

// Estimated costs
export const ADVERTISEMENT_STATUS_ID = 1
export const SENT_BUDGE_STATUS_ID = 2
export const ACEPTED_STATUS_ID = 3
export const CANCELLED_STATUS_ID = 4
export const ADVERTISEMENT_STATUS_TEXT = 'Aviso enviado'
export const SENT_BUDGE_STATUS_TEXT = 'Enviado presupuesto'
export const ACEPTED_STATUS_TEXT = 'Aceptado'
export const CANCELLED_STATUS_TEXT = 'Cancelado'

// User roles
export const BASIC_USER_ROLE_ID = 1
export const DASHBOARD_USER_MIN_ROLE_ID = 2
export const PURCHASE_USER_ROLE_ID = 3
export const ADMIN_ROLE_ID = 4
export const DASHBOARD_BUSINESS_USER_ROLE = 5
export const DASHBOARD_REPORTING_USER_ROLE = 6

// SHIPMENTS TYPE
export const SHIPMENT_TYPE_CONSOLIDATED = 'CONSOLIDADO'
export const SHIPMENT_TYPE_DIRECT = 'DIRECTO'
export const TIPSA_AGENCY_CODE = '038000'
export const TIPSA_AGENCY_SERVICE_TYPE_DIRECT = '000000'
export const TIPSA_AGENCY_SERVICE_TYPE_CONSOLIDATED = '038000'
export const CORREOS_SERVICE_TYPE_STANDARD_STR = 'Standard'
export const CORREOS_SERVICE_TYPE_PREMIUM_STR = 'Express'

export const CURRENT_DATE_TIME = Intl.DateTimeFormat('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }).format(new Date())

// SUPER_USER_LIST
export const SUPERUSER_LIST = ['joaquin.rodriguez@symbyosys.es', 'juancarlos.campos@symbyosys.es', 'fdelrio@lastsmile.es', 'odeniz@lastsmile.es']
export const SUPERUSER_WALLET_LIST = ['joaquin.rodriguez@symbyosys.es', 'juancarlos.campos@symbyosys.es', 'fdelrio@lastsmile.es', 'odeniz@lastsmile.es', 'lsouto@lastsmile.es']

export const SUPPLEMENT_PREDEFINED_OPTIONS = [
  'reducedIva',
  'reducedIvaSecondHand',
  'reducedIvaBreakdown',
  'reducedIvaEU',
  'reducedIvaShipment',
  'transportToPeninsula',
  'transportSupplement',
  'priceChange',
  'penalization',
  'refund',
  'refundTransport',
  'refundStore',
  'refundByCancelation',
  'tariff',
  'igic',
  'shippingInsurancePrice',
  'other',
  'unified'
]

export const predefinedSizes = {
  XS: { weight: 1, length: 35, height: 20, depth: 5, volume: 0.7 },
  M: { weight: 2, length: 30, height: 20, depth: 15, volume: 2 },
  L: { weight: 4, length: 40, height: 25, depth: 20, volume: 4 }
} as any

export const MAX_IGIC_VALUE = 150
export const IGIC_TYPE_GENERAL = 'general'
export const IGIC_TYPE_REDUCIDO = 'reducido'

export const DEFAULT_IVA = 21

export const LOW_VALUE_LIMIT = 'LOW_VALUE_LIMIT'
export const SHIPPING_INSURANCE = 'SHIPPING_INSURANCE'
export const FREE_SHIPMENT_ECONOMY = 'FREE_SHIPMENT_ECONOMY'
export const FREE_ECONOMY_PRICE_MIN = 'FREE_ECONOMY_PRICE_MIN'
export const FREE_SUPPLEMENTS_ECONOMY = 'FREE_SUPPLEMENTS_ECONOMY'
export const SHIPPING_INSURANCE_RANGES = []

export const CREDIT_CARD_METHOD_ID = 1

export const SHIPPING_METHOD_ECONOMY_ID = 4

export const ORDER_TYPE = {
  intern: 'Interno',
  custom: 'Personalizado'
} as const

export const REFUND_TYPE = {
  client: 'client',
  supplier: 'supplier'
} as const

export const MAX_ROWS_SALES = 2000
export const MAX_ROWS_QUERY = 2000

export const SITES_DOMAINS_EXCLUDES = [
  'es',
  'ES',
  'au',
  'com',
  'COM',
  'checkout',
  'checkout1',
  'org',
  'eu',
  'EU',
  'buy',
  'gg',
  'secure',
  'pay',
  'net',
  'de',
  'co',
  'gr',
  'uk',
  'www',
  'www2',
  'Www',
  'WWW',
  'it',
  'io',
  'm',
  'store',
  'tienda',
  'estore',
  'outlet',
  'shop',
  'merch',
  'ch',
  'nl',
  'in',
  'fr',
  'cz',
  'tech',
  'dk',
  'pt',
  'pl',
  'page',
  'mobile'
]

// BEGIN ISSUES CONSTANTS
// Issue type ids
export const ISSUE_TYPE_REFUND_MADRID_ID = 1
export const ISSUE_TYPE_REFUND_CANARY_ID = 2
export const ISSUE_TYPE_AFTER_SALE_ID = 3
export const ISSUE_TYPE_TRANSPORT_ID = 4
export const ISSUE_TYPE_WEREHOUSE_ID = 5
export const ISSUE_TYPE_IDS: IssueTypeIdTypes[] = [
  ISSUE_TYPE_REFUND_MADRID_ID,
  ISSUE_TYPE_REFUND_CANARY_ID,
  ISSUE_TYPE_AFTER_SALE_ID,
  ISSUE_TYPE_TRANSPORT_ID,
  ISSUE_TYPE_WEREHOUSE_ID
]
export const ISSUE_TYPE_REFUND_MADRID_TEXT = 'refund_madrid'
export const ISSUE_TYPE_REFUND_CANARY_TEXT = 'refund_canary'
export const ISSUE_TYPE_AFTER_SALE_TEXT = 'after_sale'
export const ISSUE_TYPE_TRANSPORT_TEXT = 'transport'
export const ISSUE_TYPE_WEREHOUSE_TEXT = 'werehouse'

// Issue sub type ids
export const ISSUE_SUB_TYPE_CANCEL_STORAGE_ID = 1
export const ISSUE_SUB_TYPE_PENALTY_ID = 2
export const ISSUE_SUB_TYPE_PARTIAL_REFUND_ID = 3
export const ISSUE_SUB_TYPE_REFUND_ID = 4
export const ISSUE_SUB_TYPE_REPLACE_ID = 5
export const ISSUE_SUB_TYPE_GUARANTEE_ID = 6
export const ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_ID = 7
export const ISSUE_SUB_TYPE_BROKEN_ORDER_ID = 8
export const ISSUE_SUB_TYPE_CROSSED_ORDER_ID = 9
export const ISSUE_SUB_TYPE_UNDELIVERED_ORDER_ID = 10
export const ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_ID = 11
export const ISSUE_SUB_TYPE_WRONG_ORDERS_ID = 12
export const ISSUE_SUB_TYPE_ORDERS_NOT_LOCATED_ID = 13
export const ISSUE_SUB_TYPE_POORLY_PREPARED_ORDERS_ID = 14
export const ISSUE_SUB_TYPE_CANCEL_STORAGE_TEXT = 'cancel_storage'
export const ISSUE_SUB_TYPE_PENALTY_TEXT = 'penalty'
export const ISSUE_SUB_TYPE_PARTIAL_REFUND_TEXT = 'partial_refund'
export const ISSUE_SUB_TYPE_REFUND_TEXT = 'refund'
export const ISSUE_SUB_TYPE_REPLACE_TEXT = 'replace'
export const ISSUE_SUB_TYPE_GUARANTEE_TEXT = 'guarantee'
export const ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_TEXT = 'double_shipment'
export const ISSUE_SUB_TYPE_BROKEN_ORDER_TEXT = 'broken_order'
export const ISSUE_SUB_TYPE_CROSSED_ORDER_TEXT = 'crossed_order'
export const ISSUE_SUB_TYPE_UNDELIVERED_ORDER_TEXT = 'undelivered_order'
export const ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_TEXT = 'untraceable_order'
export const ISSUE_SUB_TYPE_WRONG_ORDERS_TEXT = 'wrong_order'
export const ISSUE_SUB_TYPE_ORDERS_NOT_LOCATED_TEXT = 'orders_not_located'
export const ISSUE_SUB_TYPE_POORLY_PREPARED_ORDERS_TEXT = 'poorly_prepared_orders'

// Client status ids
export const CLIENT_STATUS_CONTACTED_ID = 1
export const CLIENT_STATUS_PENDING_REFUND_COSTS_ID = 2
export const CLIENT_STATUS_REFUNDED_ID = 3
export const CLIENT_STATUS_TIPSA_CONTACTED_ID = 4
export const CLIENT_STATUS_RECEIVED_ID = 5
export const CLIENT_STATUS_NO_REFUND_ID = 6
export const CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_ID = 7
export const CLIENT_STATUS_PENDING_RESOLUTIONS_ID = 8
export const CLIENT_STATUS_NEW_PURCHASE_ID = 9
export const CLIENT_STATUS_SHIPPING_NEW_ORDER_ID = 10
export const CLIENT_STATUS_WARRANTY_ACCEPTED_ID = 11
export const CLIENT_STATUS_WARRANTY_REJECTED_ID = 12
export const CLIENT_STATUS_SUPPLEMENT_SENT_ID = 13
export const CLIENT_STATUS_OFFICE_DELIVERY_REQUEST_ID = 14
export const CLIENT_STATUS_CUSTOMER_ORDER_LOCATED_SENT_ID = 15
export const CLIENT_STATUS_CUSTOMER_ORDER_NO_LOCATED_ID = 16
export const CLIENT_STATUS_PENDING_LOCATION_ID = 17
export const CLIENT_STATUS_CLIENT_NOT_FOUND_ID = 19
export const CLIENT_STATUS_CLIENT_LOCATED_ID = 20
export const CLIENT_STATUS_CLIENT_CONTACTED_ID = 21
export const CLIENT_STATUS_CONTACTED_TEXT = 'contacted'
export const CLIENT_STATUS_PENDING_REFUND_COSTS_TEXT = 'pending_refund_costs'
export const CLIENT_STATUS_REFUNDED_TEXT = 'refunded'
export const CLIENT_STATUS_TIPSA_CONTACTED_TEXT = 'tipsa_contacted'
export const CLIENT_STATUS_RECEIVED_TEXT = 'received'
export const CLIENT_STATUS_NO_REFUND_TEXT = 'no_refund'
export const CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_TEXT = 'no_refund_not_suitable'
export const CLIENT_STATUS_PENDING_RESOLUTIONS_TEXT = 'pending_resolutions'
export const CLIENT_STATUS_NEW_PURCHASE_TEXT = 'new_purchase'
export const CLIENT_STATUS_SHIPPING_NEW_ORDER_TEXT = 'shipping_new_order'
export const CLIENT_STATUS_WARRANTY_ACCEPTED_TEXT = 'warranty_accepted'
export const CLIENT_STATUS_WARRANTY_REJECTED_TEXT = 'warranty_rejected'
export const CLIENT_STATUS_SUPPLEMENT_SENT_TEXT = 'supplement_sent'
export const CLIENT_STATUS_OFFICE_DELIVERY_REQUEST_TEXT = 'office_delivery_request'
export const CLIENT_STATUS_CUSTOMER_ORDER_LOCATED_SENT_TEXT = 'customer_order_located_sent'
export const CLIENT_STATUS_CUSTOMER_ORDER_NO_LOCATED_TEXT = 'customer_order_no_located'
export const CLIENT_STATUS_PENDING_LOCATION_TEXT = 'pending_location'
export const CLIENT_STATUS_CLIENT_NOT_FOUND_TEXT = 'client_not_found'
export const CLIENT_STATUS_CLIENT_LOCATED_TEXT = 'client_located'
export const CLIENT_STATUS_CLIENT_CONTACTED_TEXT = 'client_contacted'

// Issue status ids
export const ISSUE_STATUS_CONTACTED_MARKETPLACE_ID = 1
export const ISSUE_STATUS_NO_REFUND_MARKETPLACE_ID = 2
export const ISSUE_STATUS_PENDING_PICKUP_DELIVERY_ID = 3
export const ISSUE_STATUS_REFUNDED_ID = 4
export const ISSUE_STATUS_REFUND_RECEIVED_ID = 5
export const ISSUE_STATUS_OFFICE_RECEIVED_ID = 6
export const ISSUE_STATUS_REFUND_READY_ID = 7
export const ISSUE_STATUS_SENT_TO_MADRID_ID = 8
export const ISSUE_STATUS_STORAGE_RECEIVED_ID = 9
export const ISSUE_STATUS_PENDING_RESOLUTION_ID = 10
export const ISSUE_STATUS_NEW_ORDER_SHIPMENT_ID = 11
export const ISSUE_STATUS_PENDING_BRAND_CONTACT_ID = 12
export const ISSUE_STATUS_NO_REFUND_RECEIVED_ID = 13
export const ISSUE_STATUS_CONTACTED_TIPSA_ID = 14
export const ISSUE_STATUS_REQUESTED_POD_SUPPLIER_ID = 15
export const ISSUE_STATUS_ORDER_LOCATED_ID = 16
export const ISSUE_STATUS_NO_REFUND_ID = 17
export const ISSUE_STATUS_LOCALIZED_LUMP_ID = 18
export const ISSUE_STATUS_PENDING_RECEIPT_WAREHOUSE_ID = 19
export const ISSUE_STATUS_SECOND_SHIPMENT_MADE_ID = 20
export const ISSUE_STATUS_CUSTOMER_ORDER_LOCATED_SHIPPED_CROSSED_ID = 21
export const ISSUE_STATUS_CUSTOMER_ORDER_NO_LOCATED_SHIPPED_CROSSED_ID = 22
export const ISSUE_STATUS_CLAIMING_SUPPLIER_ID = 23
export const ISSUE_STATUS_CLAIMING_TIPSA_ID = 24
export const ISSUE_STATUS_TIPSA_REFUND_REQUEST_ID = 25
export const ISSUE_STATUS_TIPSA_REFUND_RECEIVED_ID = 26
export const ISSUE_STATUS_NO_LOCALIZED_LUMP_ID = 26
export const ISSUE_STATUS_OFFICE_DELIVERY_REQUEST_ID = 27
export const ISSUE_STATUS_OFFICE_DELIVERY_RETRIEVE_ID = 28
export const ISSUE_STATUS_ORDER_NOT_FOUND_ID = 29
export const ISSUE_STATUS_CONTACTED_MARKETPLACE_TEXT = 'contacted_marketplace'
export const ISSUE_STATUS_NO_REFUND_MARKETPLACE_TEXT = 'no_refund_marketplace'
export const ISSUE_STATUS_PENDING_PICKUP_DELIVERY_TEXT = 'pending_pickup_delivery'
export const ISSUE_STATUS_REFUNDED_TEXT = 'refunded'
export const ISSUE_STATUS_REFUND_RECEIVED_TEXT = 'refund_received'
export const ISSUE_STATUS_NO_REFUND_RECEIVED_TEXT = 'no_refund_received'
export const ISSUE_STATUS_OFFICE_RECEIVED_TEXT = 'office_received'
export const ISSUE_STATUS_REFUND_READY_TEXT = 'refund_ready'
export const ISSUE_STATUS_SENT_TO_MADRID_TEXT = 'sent_to_madrid'
export const ISSUE_STATUS_STORAGE_RECEIVED_TEXT = 'storage_received'
export const ISSUE_STATUS_PENDING_RESOLUTION_TEXT = 'pending_resolution'
export const ISSUE_STATUS_NEW_ORDER_SHIPMENT_TEXT = 'new_order_shipment'
export const ISSUE_STATUS_PENDING_BRAND_CONTACT_TEXT = 'pending_brand_contact'
export const ISSUE_STATUS_CONTACTED_TIPSA_TEXT = 'contacted_tipsa'
export const ISSUE_STATUS_REQUESTED_POD_SUPPLIER_TEXT = 'requested_pod_supplier'
export const ISSUE_STATUS_ORDER_LOCATED_TEXT = 'order_located'
export const ISSUE_STATUS_NO_REFUND_TEXT = 'no_refund'
export const ISSUE_STATUS_LOCALIZED_LUMP_TEXT = 'localized_lump'
export const ISSUE_STATUS_NO_LOCALIZED_LUMP_TEXT = 'no_localized_lump'
export const ISSUE_STATUS_OFFICE_DELIVERY_REQUEST_TEXT = 'office_delivery_request'
export const ISSUE_STATUS_PENDING_RECEIPT_WAREHOUSE_TEXT = 'pending_receipt_warehouse'
export const ISSUE_STATUS_SECOND_SHIPMENT_MADE_TEXT = 'second_shipment_made'
export const ISSUE_STATUS_CUSTOMER_ORDER_LOCATED_SHIPPED_CROSSED_TEXT = 'customer_order_located_shipped_crossed'
export const ISSUE_STATUS_CUSTOMER_ORDER_NO_LOCATED_SHIPPED_CROSSED_TEXT = 'customer_order_no_located_shipped_crossed'
export const ISSUE_STATUS_CLAIMING_SUPPLIER_TEXT = 'claiming_supplier'
export const ISSUE_STATUS_CLAIMING_TIPSA_TEXT = 'claiming_TIPSA'
export const ISSUE_STATUS_TIPSA_REFUND_REQUEST_TEXT = 'TIPSA_refund_request'
export const ISSUE_STATUS_TIPSA_REFUND_RECEIVED_TEXT = 'TIPSA_refund_received'
export const ISSUE_STATUS_OFFICE_DELIVERY_RETRIEVE_TEXT = 'office_delivery_retrieve'
export const ISSUE_STATUS_ORDER_NOT_FOUND_TEXT = 'order_not_found'
// END ISSUES CONSTANTS

// Business Orders status ids
export const BUSINESS_STATUS_REQUESTED_ID = 1
export const BUSINESS_STATUS_LABELLED_ID = 2
export const BUSINESS_STATUS_RECEIVED_ID = 3
export const BUSINESS_STATUS_SENT_ID = 4
export const BUSINESS_STATUS_DELIVERED_ID = 5
export const BUSINESS_STATUS_CANCELLED_ID = 6
export const BUSINESS_STATUS_IDS: BusinessStatusIdTypes[] = [
  BUSINESS_STATUS_REQUESTED_ID,
  BUSINESS_STATUS_LABELLED_ID,
  BUSINESS_STATUS_RECEIVED_ID,
  BUSINESS_STATUS_SENT_ID,
  BUSINESS_STATUS_DELIVERED_ID,
  BUSINESS_STATUS_CANCELLED_ID
]
export const BUSINESS_STATUS_REQUESTED_TEXT = 'required'
export const BUSINESS_STATUS_LABELLED_TEXT = 'labelled'
export const BUSINESS_STATUS_RECEIVED_TEXT = 'received'
export const BUSINESS_STATUS_SENT_TEXT = 'sent'
export const BUSINESS_STATUS_DELIVERED_TEXT = 'delivered'
export const BUSINESS_STATUS_CANCELLED_TEXT = 'cancelled'

// Islands ids
export const GRAN_CANARIA_ISLAND_ID = 1
export const TENERIFE_ISLAND_ID = 2
export const FUERTEVENTURA_ISLAND_ID = 3
export const LANZAROTE_ISLAND_ID = 4
export const LA_PALMA_ISLAND_ID = 5
export const LA_GOMERA_ISLAND_ID = 6
export const EL_HIERRO_ISLAND_ID = 7
export const LA_GRACIOSA_ISLAND_ID = 8

export const LOGS_TABLES = ['company_order', 'issues']

export const FILTER_ALL = 'all'

export const GENDER_NO_GENDER = 0
export const GENDER_MAN = 1
export const GENDER_WOMAN = 2
export const GENDER_OTHER = 3

export const PURCHASE_REVIEWED_TEMPLATE_PATH = '/templates/purchases-reviewed-template.xlsx'
export const PURCHASE_CONSOLIDATED_TEMPLATE_PATH = '/templates/purchases-consolidated-template.xlsx'

// Selectable help buttons
export const VOLUMETRIC_CALCULATOR = 'volumetricCalculator'

// Referral benefits
export const REFERRAL_BENEFIT_STATUS_PENDING = 'pending'
export const REFERRAL_BENEFIT_STATUS_ACCEPTED = 'accepted'
export const REFERRAL_BENEFIT_STATUS_MODIFIED = 'modified'
export const PROMO_ORDER_PROCESSED = 'PROMO_ORDER_PROCESSED'
export const PROMO_ORDER_PROCESSED_VALUE = 'PROMO_ORDER_PROCESSED_VALUE'
export const PROMO_ORDER_PROCESSED_DAYS = 'PROMO_ORDER_PROCESSED_DAYS'
export const SYNIVA_DEFAULT_USER = 0

// decrease types
export const IVA_NOT_RECEIVED_ID = 0
export const LOST_ORDERS_WITH_COMPENSATION_ID = 1
export const LOST_ORDERS_WITHOUT_COMPENSATION_ID = 2
export const RETURN_COSTS_ID = 3
export const RETURN_REFUSED_ID = 4
export const RETURN_WITH_COUPON_VOUCHER_ID = 5
export const IVA_NOT_RECEIVED_TEXT = 'iva_not_received'
export const LOST_ORDERS_WITH_COMPENSATION_TEXT = 'lost_orders_with_compensation'
export const LOST_ORDERS_WITHOUT_COMPENSATION_TEXT = 'lost_orders_without_compensation'
export const RETURN_COSTS_TEXT = 'return_costs'
export const RETURN_REFUSED_TEXT = 'return_refused'
export const RETURN_WITH_COUPON_VOUCHER_TEXT = 'return_with_coupon_voucher'

// historyModal
export const EXCLUDED_KEYS = [
  'updated_by',
  'updated_at',
  'created_at',
  'deleted',
  'read_by_user',
  'volumetricInfo',
  'is_purchase',
  'purchase_parent',
  'marketplace_extra_input',
  'scrapped_data',
  'purchase_conditions'
]

// Profile
export const ONE_DAY = 'oneDay'
export const MULTIPLES_DATES = 'multiplesDates'
export const HOURS = 'hours'

export const ABSENT_TYPE_ILLNESS = 'illness'
export const ABSENT_TYPE_PERSONAL = 'personal'
export const ABSENT_TYPE_PERMISSION = 'permission'
export const ABSENT_TYPE_VACATION = 'vacation'

// Market order payments
export const PAYMENT_TYPE_CARD = 'card'
export const PAYMENT_TYPE_CARD_ID = 1
export const PAYMENT_TYPE_BIZUM = 'bizum'
export const PAYMENT_TYPE_BIZUM_ID = 2
export const PAYMENT_TYPE_PAYPAL = 'paypal'
export const PAYMENT_TYPE_PAYPAL_ID = 3
export const PAYMENT_TYPE_TRANFER = 'transfer'
export const PAYMENT_TYPE_TRANFER_ID = 4
