import {
  CLIENT_STATUS_CONTACTED_ID,
  CLIENT_STATUS_CONTACTED_TEXT,
  CLIENT_STATUS_NO_REFUND_ID,
  CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_ID,
  CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_TEXT,
  CLIENT_STATUS_NO_REFUND_TEXT,
  CLIENT_STATUS_PENDING_REFUND_COSTS_ID,
  CLIENT_STATUS_PENDING_REFUND_COSTS_TEXT,
  CLIENT_STATUS_RECEIVED_TEXT,
  CLIENT_STATUS_REFUNDED_ID,
  CLIENT_STATUS_REFUNDED_TEXT,
  CLIENT_STATUS_TIPSA_CONTACTED_ID,
  CLIENT_STATUS_TIPSA_CONTACTED_TEXT,
  ISSUE_SUB_TYPE_BROKEN_ORDER_ID,
  ISSUE_SUB_TYPE_BROKEN_ORDER_TEXT,
  ISSUE_SUB_TYPE_CANCEL_STORAGE_ID,
  ISSUE_SUB_TYPE_CANCEL_STORAGE_TEXT,
  ISSUE_SUB_TYPE_CROSSED_ORDER_ID,
  ISSUE_SUB_TYPE_CROSSED_ORDER_TEXT,
  ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_ID,
  ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_TEXT,
  ISSUE_SUB_TYPE_GUARANTEE_ID,
  ISSUE_SUB_TYPE_GUARANTEE_TEXT,
  ISSUE_SUB_TYPE_PARTIAL_REFUND_ID,
  ISSUE_SUB_TYPE_PARTIAL_REFUND_TEXT,
  ISSUE_SUB_TYPE_PENALTY_ID,
  ISSUE_SUB_TYPE_PENALTY_TEXT,
  ISSUE_SUB_TYPE_REFUND_ID,
  ISSUE_SUB_TYPE_REFUND_TEXT,
  ISSUE_SUB_TYPE_REPLACE_ID,
  ISSUE_SUB_TYPE_REPLACE_TEXT,
  ISSUE_SUB_TYPE_UNDELIVERED_ORDER_ID,
  ISSUE_SUB_TYPE_UNDELIVERED_ORDER_TEXT,
  ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_ID,
  ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_TEXT,
  ISSUE_TYPE_AFTER_SALE_ID,
  ISSUE_TYPE_AFTER_SALE_TEXT,
  ISSUE_TYPE_REFUND_CANARY_ID,
  ISSUE_TYPE_REFUND_CANARY_TEXT,
  ISSUE_TYPE_REFUND_MADRID_ID,
  ISSUE_TYPE_REFUND_MADRID_TEXT,
  ISSUE_TYPE_TRANSPORT_ID,
  ISSUE_TYPE_TRANSPORT_TEXT,
  ISSUE_STATUS_CONTACTED_MARKETPLACE_ID,
  ISSUE_STATUS_CONTACTED_MARKETPLACE_TEXT,
  ISSUE_STATUS_NEW_ORDER_SHIPMENT_ID,
  ISSUE_STATUS_NEW_ORDER_SHIPMENT_TEXT,
  ISSUE_STATUS_NO_REFUND_MARKETPLACE_ID,
  ISSUE_STATUS_NO_REFUND_MARKETPLACE_TEXT,
  ISSUE_STATUS_OFFICE_RECEIVED_ID,
  ISSUE_STATUS_OFFICE_RECEIVED_TEXT,
  ISSUE_STATUS_PENDING_PICKUP_DELIVERY_ID,
  ISSUE_STATUS_PENDING_PICKUP_DELIVERY_TEXT,
  ISSUE_STATUS_PENDING_RESOLUTION_ID,
  ISSUE_STATUS_PENDING_RESOLUTION_TEXT,
  ISSUE_STATUS_REFUNDED_ID,
  ISSUE_STATUS_REFUNDED_TEXT,
  ISSUE_STATUS_REFUND_READY_ID,
  ISSUE_STATUS_REFUND_READY_TEXT,
  ISSUE_STATUS_REFUND_RECEIVED_ID,
  ISSUE_STATUS_REFUND_RECEIVED_TEXT,
  ISSUE_STATUS_NO_REFUND_RECEIVED_ID,
  ISSUE_STATUS_NO_REFUND_RECEIVED_TEXT,
  ISSUE_STATUS_SENT_TO_MADRID_ID,
  ISSUE_STATUS_SENT_TO_MADRID_TEXT,
  ISSUE_STATUS_STORAGE_RECEIVED_ID,
  ISSUE_STATUS_STORAGE_RECEIVED_TEXT,
  ISSUE_STATUS_PENDING_BRAND_CONTACT_ID,
  ISSUE_STATUS_PENDING_BRAND_CONTACT_TEXT,
  BUSINESS_STATUS_REQUESTED_ID,
  BUSINESS_STATUS_LABELLED_ID,
  BUSINESS_STATUS_RECEIVED_ID,
  BUSINESS_STATUS_SENT_ID,
  BUSINESS_STATUS_DELIVERED_ID,
  BUSINESS_STATUS_CANCELLED_ID,
  BUSINESS_STATUS_REQUESTED_TEXT,
  BUSINESS_STATUS_LABELLED_TEXT,
  BUSINESS_STATUS_RECEIVED_TEXT,
  BUSINESS_STATUS_SENT_TEXT,
  BUSINESS_STATUS_DELIVERED_TEXT,
  BUSINESS_STATUS_CANCELLED_TEXT,
  ACEPTED_STATUS_ID,
  ADVERTISEMENT_STATUS_ID,
  CANCELLED_STATUS_ID,
  SENT_BUDGE_STATUS_ID,
  ADVERTISEMENT_STATUS_TEXT,
  SENT_BUDGE_STATUS_TEXT,
  ACEPTED_STATUS_TEXT,
  CANCELLED_STATUS_TEXT,
  ISSUE_STATUS_CONTACTED_TIPSA_ID,
  ISSUE_STATUS_CONTACTED_TIPSA_TEXT,
  ISSUE_SUB_TYPE_WRONG_ORDERS_ID,
  ISSUE_SUB_TYPE_WRONG_ORDERS_TEXT,
  CLIENT_STATUS_PENDING_RESOLUTIONS_ID,
  CLIENT_STATUS_PENDING_RESOLUTIONS_TEXT,
  CLIENT_STATUS_NEW_PURCHASE_ID,
  CLIENT_STATUS_NEW_PURCHASE_TEXT,
  CLIENT_STATUS_SHIPPING_NEW_ORDER_ID,
  CLIENT_STATUS_SHIPPING_NEW_ORDER_TEXT,
  ISSUE_TYPE_WEREHOUSE_ID,
  ISSUE_TYPE_WEREHOUSE_TEXT,
  ISSUE_SUB_TYPE_ORDERS_NOT_LOCATED_ID,
  ISSUE_SUB_TYPE_ORDERS_NOT_LOCATED_TEXT,
  ISSUE_STATUS_REQUESTED_POD_SUPPLIER_ID,
  ISSUE_STATUS_REQUESTED_POD_SUPPLIER_TEXT,
  ISSUE_STATUS_ORDER_LOCATED_ID,
  ISSUE_STATUS_ORDER_LOCATED_TEXT,
  ISSUE_STATUS_NO_REFUND_ID,
  ISSUE_STATUS_NO_REFUND_TEXT,
  CLIENT_STATUS_WARRANTY_ACCEPTED_ID,
  CLIENT_STATUS_WARRANTY_ACCEPTED_TEXT,
  CLIENT_STATUS_WARRANTY_REJECTED_TEXT,
  CLIENT_STATUS_WARRANTY_REJECTED_ID,
  CLIENT_STATUS_SUPPLEMENT_SENT_ID,
  CLIENT_STATUS_SUPPLEMENT_SENT_TEXT,
  ISSUE_STATUS_LOCALIZED_LUMP_ID,
  ISSUE_STATUS_LOCALIZED_LUMP_TEXT,
  ISSUE_STATUS_PENDING_RECEIPT_WAREHOUSE_ID,
  ISSUE_STATUS_PENDING_RECEIPT_WAREHOUSE_TEXT,
  ISSUE_STATUS_SECOND_SHIPMENT_MADE_ID,
  ISSUE_STATUS_SECOND_SHIPMENT_MADE_TEXT,
  CLIENT_STATUS_OFFICE_DELIVERY_REQUEST_ID,
  CLIENT_STATUS_OFFICE_DELIVERY_REQUEST_TEXT,
  CLIENT_STATUS_CUSTOMER_ORDER_LOCATED_SENT_ID,
  CLIENT_STATUS_CUSTOMER_ORDER_NO_LOCATED_ID,
  CLIENT_STATUS_CUSTOMER_ORDER_NO_LOCATED_TEXT,
  CLIENT_STATUS_CUSTOMER_ORDER_LOCATED_SENT_TEXT,
  ISSUE_STATUS_CUSTOMER_ORDER_LOCATED_SHIPPED_CROSSED_ID,
  ISSUE_STATUS_CUSTOMER_ORDER_LOCATED_SHIPPED_CROSSED_TEXT,
  ISSUE_STATUS_CUSTOMER_ORDER_NO_LOCATED_SHIPPED_CROSSED_TEXT,
  ISSUE_STATUS_CUSTOMER_ORDER_NO_LOCATED_SHIPPED_CROSSED_ID,
  ISSUE_SUB_TYPE_POORLY_PREPARED_ORDERS_ID,
  ISSUE_SUB_TYPE_POORLY_PREPARED_ORDERS_TEXT,
  CLIENT_STATUS_PENDING_LOCATION_ID,
  CLIENT_STATUS_PENDING_LOCATION_TEXT,
  ISSUE_STATUS_CLAIMING_SUPPLIER_ID,
  ISSUE_STATUS_CLAIMING_SUPPLIER_TEXT,
  ISSUE_STATUS_CLAIMING_TIPSA_ID,
  ISSUE_STATUS_CLAIMING_TIPSA_TEXT,
  ISSUE_STATUS_TIPSA_REFUND_REQUEST_ID,
  ISSUE_STATUS_TIPSA_REFUND_REQUEST_TEXT,
  ISSUE_STATUS_TIPSA_REFUND_RECEIVED_ID,
  ISSUE_STATUS_TIPSA_REFUND_RECEIVED_TEXT,
  CLIENT_STATUS_CLIENT_NOT_FOUND_ID,
  CLIENT_STATUS_CLIENT_NOT_FOUND_TEXT,
  CLIENT_STATUS_CLIENT_LOCATED_ID,
  CLIENT_STATUS_CLIENT_LOCATED_TEXT,
  ISSUE_STATUS_NO_LOCALIZED_LUMP_ID,
  ISSUE_STATUS_NO_LOCALIZED_LUMP_TEXT,
  ISSUE_STATUS_OFFICE_DELIVERY_REQUEST_ID,
  ISSUE_STATUS_OFFICE_DELIVERY_REQUEST_TEXT,
  ISSUE_STATUS_OFFICE_DELIVERY_RETRIEVE_ID,
  ISSUE_STATUS_OFFICE_DELIVERY_RETRIEVE_TEXT,
  ISSUE_STATUS_ORDER_NOT_FOUND_ID,
  ISSUE_STATUS_ORDER_NOT_FOUND_TEXT,
  CLIENT_STATUS_CLIENT_CONTACTED_ID,
  CLIENT_STATUS_CLIENT_CONTACTED_TEXT,
  IVA_NOT_RECEIVED_ID,
  LOST_ORDERS_WITH_COMPENSATION_ID,
  LOST_ORDERS_WITHOUT_COMPENSATION_ID,
  RETURN_COSTS_ID,
  RETURN_REFUSED_ID,
  RETURN_WITH_COUPON_VOUCHER_ID,
  IVA_NOT_RECEIVED_TEXT,
  LOST_ORDERS_WITH_COMPENSATION_TEXT,
  LOST_ORDERS_WITHOUT_COMPENSATION_TEXT,
  RETURN_COSTS_TEXT,
  RETURN_REFUSED_TEXT,
  RETURN_WITH_COUPON_VOUCHER_TEXT,
  ORDER_STATUS_ID_PENDING_PAYMENT,
  ORDER_STATUS_ID_REQUESTED,
  PROCESSED_STATUS,
  CANCELLED_STATUS,
  RETURN_STATUS,
  SUPPLEMENT_STATUS,
  ADMITTED_STATUS,
  NO_STOCK_STATUS,
  PREPARED_STATUS,
  SHIPPED_STATUS,
  DELIVERED_STATUS,
  PENALIZATION_STATUS,
  REFUND_STATUS,
  PARCIAL_RETURN_STATUS,
  DECREASE_RETURN_STATUS,
  INTERNAL_SPENDING_RETURN_STATUS,
  ORDER_STATUS_ID_ON_HOLD,
  ORDER_STATUS_ID_DENIED,
  PENDING_PAYMENT_STATUS_TEXT,
  REQUEST_STATUS_TEXT,
  PROCESS_STATUS_TEXT,
  CANCEL_STATUS_TEXT,
  RETURN_STATUS_TEXT,
  SUPPLEMENT_STATUS_TEXT,
  ADMITTED_STATUS_TEXT,
  NO_STOCK_STATUS_TEXT,
  PREPARED_STATUS_TEXT,
  SHIPPED_STATUS_TEXT,
  DELIVERED_STATUS_TEXT,
  PENALIZATION_STATUS_TEXT,
  REFUND_STATUS_TEXT,
  PARCIAL_RETURN_STATUS_TEXT,
  DECREASE_RETURN_STATUS_TEXT,
  INTERNAL_SPENDING_RETURN_STATUS_TEXT,
  ORDER_STATUS_ON_HOLD_TEXT,
  ORDER_STATUS_DENIED_TEXT,
  EXPRESS_SHIPMENT_METHOD_ID,
  ESTANDAR_SHIPMENT_METHOD_ID,
  ECONOMY_SHIPMENT_METHOD_ID,
  EXPRESS_SHIPMENT_METHOD_TEXT,
  ESTANDAR_SHIPMENT_METHOD_TEXT,
  ECONOMY_SHIPMENT_METHOD_TEXT,
  PAYMENT_TYPE_BIZUM_ID,
  PAYMENT_TYPE_CARD_ID,
  PAYMENT_TYPE_PAYPAL_ID,
  PAYMENT_TYPE_TRANFER_ID,
  PAYMENT_TYPE_BIZUM,
  PAYMENT_TYPE_CARD,
  PAYMENT_TYPE_PAYPAL,
  PAYMENT_TYPE_TRANFER
} from 'utility'

// BEGIN ISSUES TYPES
// Issue type ids
export type IssueTypeIdTypes = typeof ISSUE_TYPE_REFUND_MADRID_ID | typeof ISSUE_TYPE_REFUND_CANARY_ID | typeof ISSUE_TYPE_AFTER_SALE_ID | typeof ISSUE_TYPE_TRANSPORT_ID | typeof ISSUE_TYPE_WEREHOUSE_ID
export type SubIssueTypeIdTypes = typeof ISSUE_SUB_TYPE_CANCEL_STORAGE_ID | typeof ISSUE_SUB_TYPE_PENALTY_ID | typeof ISSUE_SUB_TYPE_PARTIAL_REFUND_ID | typeof ISSUE_SUB_TYPE_REFUND_ID | typeof ISSUE_SUB_TYPE_GUARANTEE_ID | typeof ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_ID | typeof ISSUE_SUB_TYPE_BROKEN_ORDER_ID | typeof ISSUE_SUB_TYPE_CROSSED_ORDER_ID | typeof ISSUE_SUB_TYPE_UNDELIVERED_ORDER_ID | typeof ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_ID | typeof ISSUE_SUB_TYPE_WRONG_ORDERS_ID | typeof ISSUE_SUB_TYPE_ORDERS_NOT_LOCATED_ID | typeof ISSUE_SUB_TYPE_POORLY_PREPARED_ORDERS_ID
export type IssueTypeTextTypes = typeof ISSUE_TYPE_REFUND_MADRID_TEXT | typeof ISSUE_TYPE_REFUND_CANARY_TEXT | typeof ISSUE_TYPE_AFTER_SALE_TEXT | typeof ISSUE_TYPE_TRANSPORT_TEXT | typeof ISSUE_TYPE_WEREHOUSE_TEXT
export type IssueTypesMapper = {
  // eslint-disable-next-line no-unused-vars
  [key in IssueTypeIdTypes]: IssueTypeTextTypes
}

// Issue sub type ids
export type IssueSubTypeIdTypes =
  | typeof ISSUE_SUB_TYPE_CANCEL_STORAGE_ID
  | typeof ISSUE_SUB_TYPE_PENALTY_ID
  | typeof ISSUE_SUB_TYPE_PARTIAL_REFUND_ID
  | typeof ISSUE_SUB_TYPE_REFUND_ID
  | typeof ISSUE_SUB_TYPE_REPLACE_ID
  | typeof ISSUE_SUB_TYPE_GUARANTEE_ID
  | typeof ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_ID
  | typeof ISSUE_SUB_TYPE_BROKEN_ORDER_ID
  | typeof ISSUE_SUB_TYPE_CROSSED_ORDER_ID
  | typeof ISSUE_SUB_TYPE_UNDELIVERED_ORDER_ID
  | typeof ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_ID
  | typeof ISSUE_SUB_TYPE_WRONG_ORDERS_ID
  | typeof ISSUE_SUB_TYPE_ORDERS_NOT_LOCATED_ID
  | typeof ISSUE_SUB_TYPE_POORLY_PREPARED_ORDERS_ID
export type IssueSubTypeTextTypes =
  | typeof ISSUE_SUB_TYPE_CANCEL_STORAGE_TEXT
  | typeof ISSUE_SUB_TYPE_PENALTY_TEXT
  | typeof ISSUE_SUB_TYPE_PARTIAL_REFUND_TEXT
  | typeof ISSUE_SUB_TYPE_REFUND_TEXT
  | typeof ISSUE_SUB_TYPE_REPLACE_TEXT
  | typeof ISSUE_SUB_TYPE_GUARANTEE_TEXT
  | typeof ISSUE_SUB_TYPE_DOUBLE_SHIPMENT_TEXT
  | typeof ISSUE_SUB_TYPE_BROKEN_ORDER_TEXT
  | typeof ISSUE_SUB_TYPE_CROSSED_ORDER_TEXT
  | typeof ISSUE_SUB_TYPE_UNDELIVERED_ORDER_TEXT
  | typeof ISSUE_SUB_TYPE_UNTRACEABLE_ORDER_TEXT
  | typeof ISSUE_SUB_TYPE_WRONG_ORDERS_TEXT
  | typeof ISSUE_SUB_TYPE_ORDERS_NOT_LOCATED_TEXT
  | typeof ISSUE_SUB_TYPE_POORLY_PREPARED_ORDERS_TEXT
export type IssueSubTypesMapper = {
  // eslint-disable-next-line no-unused-vars
  [key in IssueSubTypeIdTypes]?: IssueSubTypeTextTypes
}

// Client status ids
export type ClientStatusIdTypes =
  | typeof CLIENT_STATUS_CONTACTED_ID
  | typeof CLIENT_STATUS_PENDING_REFUND_COSTS_ID
  | typeof CLIENT_STATUS_REFUNDED_ID
  | typeof CLIENT_STATUS_TIPSA_CONTACTED_ID
  | typeof CLIENT_STATUS_NO_REFUND_ID
  | typeof CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_ID
  | typeof CLIENT_STATUS_PENDING_RESOLUTIONS_ID
  | typeof CLIENT_STATUS_NEW_PURCHASE_ID
  | typeof CLIENT_STATUS_SHIPPING_NEW_ORDER_ID
  | typeof CLIENT_STATUS_WARRANTY_ACCEPTED_ID
  | typeof CLIENT_STATUS_WARRANTY_REJECTED_ID
  | typeof CLIENT_STATUS_SUPPLEMENT_SENT_ID
  | typeof CLIENT_STATUS_OFFICE_DELIVERY_REQUEST_ID
  | typeof CLIENT_STATUS_CUSTOMER_ORDER_LOCATED_SENT_ID
  | typeof CLIENT_STATUS_CUSTOMER_ORDER_NO_LOCATED_ID
  | typeof CLIENT_STATUS_CUSTOMER_ORDER_NO_LOCATED_ID
  | typeof ISSUE_STATUS_LOCALIZED_LUMP_ID
  | typeof CLIENT_STATUS_PENDING_LOCATION_ID
  | typeof CLIENT_STATUS_CLIENT_LOCATED_ID
  | typeof CLIENT_STATUS_CLIENT_NOT_FOUND_ID
  | typeof ISSUE_STATUS_NO_LOCALIZED_LUMP_ID
  | typeof CLIENT_STATUS_CLIENT_CONTACTED_ID
export type ClientStatusTextTypes =
  | typeof CLIENT_STATUS_CONTACTED_TEXT
  | typeof CLIENT_STATUS_PENDING_REFUND_COSTS_TEXT
  | typeof CLIENT_STATUS_REFUNDED_TEXT
  | typeof CLIENT_STATUS_TIPSA_CONTACTED_TEXT
  | typeof CLIENT_STATUS_RECEIVED_TEXT
  | typeof CLIENT_STATUS_NO_REFUND_TEXT
  | typeof CLIENT_STATUS_NO_REFUND_NOT_SUITABLE_TEXT
  | typeof CLIENT_STATUS_PENDING_RESOLUTIONS_TEXT
  | typeof CLIENT_STATUS_NEW_PURCHASE_TEXT
  | typeof CLIENT_STATUS_SHIPPING_NEW_ORDER_TEXT
  | typeof CLIENT_STATUS_WARRANTY_ACCEPTED_TEXT
  | typeof CLIENT_STATUS_WARRANTY_REJECTED_TEXT
  | typeof CLIENT_STATUS_SUPPLEMENT_SENT_TEXT
  | typeof CLIENT_STATUS_OFFICE_DELIVERY_REQUEST_TEXT
  | typeof CLIENT_STATUS_CUSTOMER_ORDER_LOCATED_SENT_TEXT
  | typeof CLIENT_STATUS_CUSTOMER_ORDER_NO_LOCATED_TEXT
  | typeof ISSUE_STATUS_LOCALIZED_LUMP_TEXT
  | typeof CLIENT_STATUS_PENDING_LOCATION_TEXT
  | typeof CLIENT_STATUS_CLIENT_NOT_FOUND_TEXT
  | typeof CLIENT_STATUS_CLIENT_LOCATED_TEXT
  | typeof ISSUE_STATUS_NO_LOCALIZED_LUMP_TEXT
  | typeof CLIENT_STATUS_CLIENT_CONTACTED_TEXT
export type ClientStatusesMapper = {
  // eslint-disable-next-line no-unused-vars
  [key in ClientStatusIdTypes]?: ClientStatusTextTypes
}

// Issue status ids
export type IssueStatusIdTypes =
  | typeof ISSUE_STATUS_CONTACTED_MARKETPLACE_ID
  | typeof ISSUE_STATUS_NO_REFUND_MARKETPLACE_ID
  | typeof ISSUE_STATUS_PENDING_PICKUP_DELIVERY_ID
  | typeof ISSUE_STATUS_REFUNDED_ID
  | typeof ISSUE_STATUS_REFUND_RECEIVED_ID
  | typeof ISSUE_STATUS_NO_REFUND_RECEIVED_ID
  | typeof ISSUE_STATUS_OFFICE_RECEIVED_ID
  | typeof ISSUE_STATUS_REFUND_READY_ID
  | typeof ISSUE_STATUS_SENT_TO_MADRID_ID
  | typeof ISSUE_STATUS_STORAGE_RECEIVED_ID
  | typeof ISSUE_STATUS_PENDING_RESOLUTION_ID
  | typeof ISSUE_STATUS_NEW_ORDER_SHIPMENT_ID
  | typeof ISSUE_STATUS_PENDING_BRAND_CONTACT_ID
  | typeof ISSUE_STATUS_CONTACTED_TIPSA_ID
  | typeof ISSUE_STATUS_REQUESTED_POD_SUPPLIER_ID
  | typeof ISSUE_STATUS_ORDER_LOCATED_ID
  | typeof ISSUE_STATUS_NO_REFUND_ID
  | typeof ISSUE_STATUS_LOCALIZED_LUMP_ID
  | typeof ISSUE_STATUS_PENDING_RECEIPT_WAREHOUSE_ID
  | typeof ISSUE_STATUS_SECOND_SHIPMENT_MADE_ID
  | typeof CLIENT_STATUS_CUSTOMER_ORDER_LOCATED_SENT_ID
  | typeof CLIENT_STATUS_CUSTOMER_ORDER_NO_LOCATED_ID
  | typeof ISSUE_STATUS_CUSTOMER_ORDER_LOCATED_SHIPPED_CROSSED_ID
  | typeof ISSUE_STATUS_CUSTOMER_ORDER_NO_LOCATED_SHIPPED_CROSSED_ID
  | typeof ISSUE_STATUS_CLAIMING_SUPPLIER_ID
  | typeof ISSUE_STATUS_CLAIMING_TIPSA_ID
  | typeof ISSUE_STATUS_TIPSA_REFUND_REQUEST_ID
  | typeof ISSUE_STATUS_TIPSA_REFUND_RECEIVED_ID
  | typeof ISSUE_STATUS_OFFICE_DELIVERY_REQUEST_ID
  | typeof ISSUE_STATUS_OFFICE_DELIVERY_RETRIEVE_ID
  | typeof ISSUE_STATUS_ORDER_NOT_FOUND_ID
export type IssueStatusTextTypes =
  | typeof ISSUE_STATUS_CONTACTED_MARKETPLACE_TEXT
  | typeof ISSUE_STATUS_NO_REFUND_MARKETPLACE_TEXT
  | typeof ISSUE_STATUS_PENDING_PICKUP_DELIVERY_TEXT
  | typeof ISSUE_STATUS_REFUNDED_TEXT
  | typeof ISSUE_STATUS_REFUND_RECEIVED_TEXT
  | typeof ISSUE_STATUS_NO_REFUND_RECEIVED_TEXT
  | typeof ISSUE_STATUS_OFFICE_RECEIVED_TEXT
  | typeof ISSUE_STATUS_REFUND_READY_TEXT
  | typeof ISSUE_STATUS_SENT_TO_MADRID_TEXT
  | typeof ISSUE_STATUS_STORAGE_RECEIVED_TEXT
  | typeof ISSUE_STATUS_PENDING_RESOLUTION_TEXT
  | typeof ISSUE_STATUS_NEW_ORDER_SHIPMENT_TEXT
  | typeof ISSUE_STATUS_PENDING_BRAND_CONTACT_TEXT
  | typeof ISSUE_STATUS_CONTACTED_TIPSA_TEXT
  | typeof ISSUE_STATUS_REQUESTED_POD_SUPPLIER_TEXT
  | typeof ISSUE_STATUS_ORDER_LOCATED_TEXT
  | typeof ISSUE_STATUS_NO_REFUND_TEXT
  | typeof ISSUE_STATUS_LOCALIZED_LUMP_TEXT
  | typeof ISSUE_STATUS_PENDING_RECEIPT_WAREHOUSE_TEXT
  | typeof ISSUE_STATUS_SECOND_SHIPMENT_MADE_TEXT
  | typeof ISSUE_STATUS_CUSTOMER_ORDER_LOCATED_SHIPPED_CROSSED_TEXT
  | typeof ISSUE_STATUS_CUSTOMER_ORDER_NO_LOCATED_SHIPPED_CROSSED_TEXT
  | typeof ISSUE_STATUS_CLAIMING_SUPPLIER_TEXT
  | typeof ISSUE_STATUS_CLAIMING_TIPSA_TEXT
  | typeof ISSUE_STATUS_TIPSA_REFUND_REQUEST_TEXT
  | typeof ISSUE_STATUS_TIPSA_REFUND_RECEIVED_TEXT
  | typeof ISSUE_STATUS_OFFICE_DELIVERY_REQUEST_TEXT
  | typeof ISSUE_STATUS_OFFICE_DELIVERY_RETRIEVE_TEXT
  | typeof ISSUE_STATUS_ORDER_NOT_FOUND_TEXT
export type IssueStatusMapper = {
  // eslint-disable-next-line no-unused-vars
  [key in IssueStatusIdTypes]?: IssueStatusTextTypes
}

export type IssueTypesConfig = {
  // eslint-disable-next-line no-unused-vars
  [key in IssueTypeIdTypes]: {
    issueSubTypes: IssueSubTypesMapper,
    clientStatuses?: ClientStatusesMapper,
    issueStatuses?: IssueStatusMapper
  }
}

export type SubIssueTypesConfig = {
  // eslint-disable-next-line no-unused-vars
  [key in SubIssueTypeIdTypes]: {
    clientStatuses?: ClientStatusesMapper,
    issueStatuses?: IssueStatusMapper,
  }
}
// END ISSUES CONSTANTS

export type BusinessStatusIdTypes = typeof BUSINESS_STATUS_REQUESTED_ID | typeof BUSINESS_STATUS_LABELLED_ID | typeof BUSINESS_STATUS_RECEIVED_ID | typeof BUSINESS_STATUS_SENT_ID | typeof BUSINESS_STATUS_DELIVERED_ID | typeof BUSINESS_STATUS_CANCELLED_ID
export type BusinessStatusTextTypes = typeof BUSINESS_STATUS_REQUESTED_TEXT | typeof BUSINESS_STATUS_LABELLED_TEXT | typeof BUSINESS_STATUS_RECEIVED_TEXT | typeof BUSINESS_STATUS_SENT_TEXT | typeof BUSINESS_STATUS_DELIVERED_TEXT | typeof BUSINESS_STATUS_CANCELLED_TEXT

export type BusinessStatusMapper = {
  // eslint-disable-next-line no-unused-vars
  [key in BusinessStatusIdTypes]: BusinessStatusTextTypes
}

export const isBusinessStatusId: (value: any) => value is BusinessStatusIdTypes = (value): value is BusinessStatusIdTypes => {
  return [
    BUSINESS_STATUS_REQUESTED_ID,
    BUSINESS_STATUS_LABELLED_ID,
    BUSINESS_STATUS_RECEIVED_ID,
    BUSINESS_STATUS_SENT_ID,
    BUSINESS_STATUS_DELIVERED_ID,
    BUSINESS_STATUS_CANCELLED_ID
  ].includes(value)
}

export type EstimatedCostsStatusIdTypes = typeof ADVERTISEMENT_STATUS_ID | typeof SENT_BUDGE_STATUS_ID | typeof ACEPTED_STATUS_ID | typeof CANCELLED_STATUS_ID
export type EstimatedCostsStatusTextTypes = typeof ADVERTISEMENT_STATUS_TEXT | typeof SENT_BUDGE_STATUS_TEXT | typeof ACEPTED_STATUS_TEXT | typeof CANCELLED_STATUS_TEXT
export type EstimatedCostsStatusMapper = {
  // eslint-disable-next-line no-unused-vars
  [key in EstimatedCostsStatusIdTypes]: EstimatedCostsStatusTextTypes
}

// decrease Types
export type DecreaseTypeIdTypes =
  | typeof IVA_NOT_RECEIVED_ID
  | typeof LOST_ORDERS_WITH_COMPENSATION_ID
  | typeof LOST_ORDERS_WITHOUT_COMPENSATION_ID
  | typeof RETURN_COSTS_ID
  | typeof RETURN_REFUSED_ID
  | typeof RETURN_WITH_COUPON_VOUCHER_ID
export type DecreaseTypeTextTypes =
  | typeof IVA_NOT_RECEIVED_TEXT
  | typeof LOST_ORDERS_WITH_COMPENSATION_TEXT
  | typeof LOST_ORDERS_WITHOUT_COMPENSATION_TEXT
  | typeof RETURN_COSTS_TEXT
  | typeof RETURN_REFUSED_TEXT
  | typeof RETURN_WITH_COUPON_VOUCHER_TEXT

export type DecreaseTypesMapper = {
  // eslint-disable-next-line no-unused-vars
  [key in DecreaseTypeIdTypes]: DecreaseTypeTextTypes
}

export type OrderStatusId =
  | typeof ORDER_STATUS_ID_PENDING_PAYMENT
  | typeof ORDER_STATUS_ID_REQUESTED
  | typeof PROCESSED_STATUS
  | typeof CANCELLED_STATUS
  | typeof RETURN_STATUS
  | typeof SUPPLEMENT_STATUS
  | typeof ADMITTED_STATUS
  | typeof NO_STOCK_STATUS
  | typeof PREPARED_STATUS
  | typeof SHIPPED_STATUS
  | typeof DELIVERED_STATUS
  | typeof PENALIZATION_STATUS
  | typeof REFUND_STATUS
  | typeof PARCIAL_RETURN_STATUS
  | typeof DECREASE_RETURN_STATUS
  | typeof INTERNAL_SPENDING_RETURN_STATUS
  | typeof ORDER_STATUS_ID_ON_HOLD
  | typeof ORDER_STATUS_ID_DENIED

export type OrderStatusText =
  | typeof PENDING_PAYMENT_STATUS_TEXT
  | typeof REQUEST_STATUS_TEXT
  | typeof PROCESS_STATUS_TEXT
  | typeof CANCEL_STATUS_TEXT
  | typeof RETURN_STATUS_TEXT
  | typeof SUPPLEMENT_STATUS_TEXT
  | typeof ADMITTED_STATUS_TEXT
  | typeof NO_STOCK_STATUS_TEXT
  | typeof PREPARED_STATUS_TEXT
  | typeof SHIPPED_STATUS_TEXT
  | typeof DELIVERED_STATUS_TEXT
  | typeof PENALIZATION_STATUS_TEXT
  | typeof REFUND_STATUS_TEXT
  | typeof PARCIAL_RETURN_STATUS_TEXT
  | typeof DECREASE_RETURN_STATUS_TEXT
  | typeof INTERNAL_SPENDING_RETURN_STATUS_TEXT
  | typeof ORDER_STATUS_ON_HOLD_TEXT
  | typeof ORDER_STATUS_DENIED_TEXT

export type OrderStatusMapper = {
    // eslint-disable-next-line no-unused-vars
    [key in OrderStatusId]: OrderStatusText
}

export type ShipmentMethodId =
  | typeof EXPRESS_SHIPMENT_METHOD_ID
  | typeof ESTANDAR_SHIPMENT_METHOD_ID
  | typeof ECONOMY_SHIPMENT_METHOD_ID

export type ShipmentMethodText =
  | typeof EXPRESS_SHIPMENT_METHOD_TEXT
  | typeof ESTANDAR_SHIPMENT_METHOD_TEXT
  | typeof ECONOMY_SHIPMENT_METHOD_TEXT

export type ShipmentMethodMapper = {
    // eslint-disable-next-line no-unused-vars
    [key in ShipmentMethodId]: ShipmentMethodText
}

export type PaymentPurchaseMethodId =
  | typeof PAYMENT_TYPE_CARD_ID
  | typeof PAYMENT_TYPE_BIZUM_ID
  | typeof PAYMENT_TYPE_PAYPAL_ID
  | typeof PAYMENT_TYPE_TRANFER_ID

export type PaymentPurchaseMethodText =
  | typeof PAYMENT_TYPE_CARD
  | typeof PAYMENT_TYPE_BIZUM
  | typeof PAYMENT_TYPE_PAYPAL
  | typeof PAYMENT_TYPE_TRANFER

export type PaymentPurchaseMethodMapper = {
    // eslint-disable-next-line no-unused-vars
    [key in PaymentPurchaseMethodId]: PaymentPurchaseMethodText
}
